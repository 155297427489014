import { Container } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import '../fonts/fonts.css';
import React from 'react';
import SEO from '../components/SEO';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    webPageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      marginTop: 15,
    },
    descriptionPlaceholder: {
      textAlign: 'left',
      fontFamily: 'Roboto Mono',
    },
    latestPublicationsFeedContainerTitle: {
      marginTop: 20,
      fontSize: 28,
      fontFamily: 'Roboto Mono',
      color: theme.palette.text.primary,
      textAlign: 'left',
    },
  })
);

export default function HomePage({ data }) {
  const classes = useStyles();
  const title = data.site.siteMetadata.title;
  const description = data.site.siteMetadata.description;

  return (
    <div className={classes.root}>
      <Header title={title} />
      <SEO title={title} description={description} />
      {/* <Container maxWidth="md" className={classes.webPageContainer}>
        What?
      </Container> */}
      <Footer />
    </div>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;
